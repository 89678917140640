import _stream from "stream";
import _zlib from "zlib";
import _mimicResponse from "mimic-response";
var exports = {};
const PassThrough = _stream.PassThrough;
const zlib = _zlib;
const mimicResponse = _mimicResponse;

exports = response => {
  // TODO: Use Array#includes when targeting Node.js 6
  if (["gzip", "deflate"].indexOf(response.headers["content-encoding"]) === -1) {
    return response;
  }

  const unzip = zlib.createUnzip();
  const stream = new PassThrough();
  mimicResponse(response, stream);
  unzip.on("error", err => {
    if (err.code === "Z_BUF_ERROR") {
      stream.end();
      return;
    }

    stream.emit("error", err);
  });
  response.pipe(unzip).pipe(stream);
  return stream;
};

export default exports;